/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';

interface Props {
  data: {
    file: {
      publicURL: string;
    };
  };
  pageContext: {
    html: string;
    crumbs: {
      slug: string;
      display: string;
    }[];
  };
}

export class Template extends React.Component<Props> {
  render() {
    const { pageContext, data } = this.props;
    const { publicURL } = data.file;

    return (
      <div>
        <Layout>
          <Subheader crumbs={pageContext.crumbs} />
          <img src={publicURL} />
        </Layout>
      </div>
    );
  }
}

export const query = graphql`
  query($slug: String) {
    file(fields: { slug: { eq: $slug } }) {
      publicURL
    }
  }
`;

export default Template;
